/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { AxiosResponse } from 'axios';
import {
  CustomizationData,
  AdditionalSettings,
  subscriptionClient,
  SupportContactInformationRecord,
} from '../../../../client/base';
import { useBoolean } from '@britishcouncil/react-common';
import { checkIfImageOrDefault, goToUrl } from '../../../../utils/utils';
import { GoogleAnalyticsHelpers } from '../../../../googleAnalytics/GoogleAnalyticsHelpers';
import { setAxiosHeaders } from '../../../../utils/auditInterceptorsAxiosConfig';
import { EolChat } from '../../EolChat';
import {
  HeaderBar,
  PlanSummaryProps,
  SafePaymentsBadge,
  TimerSectionProps,
} from '../../../../shared';
import { useTranslation } from 'react-i18next';
import { configureTrackingProps, EntityType, logError } from '../../../../utils/logger-utils';
import { startAppInsightTracking } from '../../applicatonInsights';
import { useLoadingContext } from '../../../../App';
import { Footer, Header, FooterLinkTexts } from '@britishcouncil/react-extro-ui';
import i18next, {
  getTranslationSettingsForHeader,
  initializeTranslationForWrapper,
} from '../../../../utils/i18n';
import CommonLayout from '../../../../shared/layout/commonLayout/CommonLayout';
import TrialInfo from '../../../../shared/trialInfo/TrialInfo';
import { AvailablePaymentMethod, ErrorMsg, PageTitle, SupportSection } from '../../..';
import PaymentMethodChanger from '../../../../shared/paymentMethodChanger/PaymentMethodChanger';
import { DiscountInfo } from '../../../../shared/summary/planSummary/components';
import CommonSubscriptionSideSection from '../../../../shared/layout/sideSection/subscription/CommonSubscriptionSideSection';
import * as S from './subscriptionWrappers.styles';
import { PageType } from '../types';
import { FiCreditCard } from 'react-icons/fi';
import { SUBSCRIPTION_IDENTIFIER_NAME } from '../../../../utils';
import { useNavigateToErrorPage, useQueryParams } from '../../../../hooks';
import { useErrorSummaryContext } from '@contexts';
import ErrorSummaryBox from '../../../../shared/errorSummary/ErrorSummaryBox';
import { SubscriptionErrorPagePath } from '../../../../routes/PathConsts';

interface SubscriptionHeaderAndFooterOptions {
  withTimer?: boolean;
  isTimerHidden?: boolean;
  withRedirectOnFinishedSubscription?: boolean;
  isUpdatingPaymentMethod?: boolean;
  isErrorPage?: boolean;
  customRedirectUrl?: string;
  withBackButton?: boolean;
  withSideSection?: boolean;
  withPaymentMethodChanger?: boolean;
  withTitle?: boolean;
  pageType?: PageType;
  alignCenter?: boolean;
}

export function subscriptionWithHeaderAndFooter<T>(
  Component: React.ComponentType<T>,
  options: SubscriptionHeaderAndFooterOptions = {
    withTimer: false,
    isTimerHidden: false,
    withRedirectOnFinishedSubscription: false,
    isUpdatingPaymentMethod: false,
    isErrorPage: true,
    withBackButton: false,
    withSideSection: false,
    withPaymentMethodChanger: false,
    withTitle: false,
    pageType: PageType.Payment,
    alignCenter: false,
  }
) {
  return (
    props: Omit<
      T,
      | 'isCollectingBillingDetails'
      | 'isUpdatingPaymentMethod'
      | 'hasTrial'
      | 'customRedirectUrl'
      | 'onErrorMessageChange'
    >
  ) => {
    const { isErrorSummaryBoxVisible } = useErrorSummaryContext();
    const { isLoading } = useLoadingContext();
    const [customizationData, setCustomizationData] = useState<CustomizationData>({});
    const [isActive, setIsActive] = useBoolean(false);
    const [customRedirectUrl, setCustomRedirectUrl] = useState<string>();
    const [isCollectingBillingDetails, setIsCollectingBillingDetails] = useBoolean();
    const [isTranslationAllowed, setIsTranslationAllowed] = useBoolean();
    const [hasTrial, setHasTrial] = useBoolean();
    const [additionalSettings, setAdditionalSettings] = useState<AdditionalSettings>();
    const [timeoutDate, setTimeoutDate] = useState<Date>();
    const [isTimeoutExpired, setIsTimeoutExpired] = useState<boolean>(false);
    const [planSummary, setPlanSummary] = useState<PlanSummaryProps>();
    const [discountInfo, setDiscountInfo] = useState<DiscountInfo>();
    const [activePaymentMethod, setActivePaymentMethod] = useState<AvailablePaymentMethod>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [supportInfo, setSupportInfo] = useState<SupportContactInformationRecord>();
    const queryParams = useQueryParams();
    const token = queryParams.getParam('token');
    const subscriptionId = queryParams.getSubscriptionId();
    const { goToSubscriptionErrorPage } = useNavigateToErrorPage();

    const { t } = useTranslation(['npp']);

    useEffect(() => {
      if (subscriptionId)
        subscriptionClient
          .getSubscriptionSupportContactInformation(subscriptionId)
          .then((result) => {
            if (result.data) setSupportInfo(result.data);
          });
    }, [subscriptionId]);

    useEffect(() => {
      const loadData = async () => {
        if (!subscriptionId) {
          goToUrl(SubscriptionErrorPagePath);
        }

        localStorage.setItem(SUBSCRIPTION_IDENTIFIER_NAME, subscriptionId);
        const configurationData = (
          await subscriptionClient.getSubscriptionConfiguration(subscriptionId)
        ).data;

        configureTrackingProps(
          configurationData.subscriptionId,
          EntityType.subscription,
          configurationData.correlationSessionId!
        );

        startAppInsightTracking();

        if (configurationData.additionalSettings) {
          setAdditionalSettings(configurationData.additionalSettings);
        }

        setActivePaymentMethod({
          name: configurationData.providerPaymentMethodGroupCode,
          shortCode: configurationData.providerPaymentMethodGroupCode,
          logo: { img: <FiCreditCard size={33} />, isExternal: false },
          methodGroupId: 0,
        });

        setAxiosHeaders(configurationData.correlationSessionId!);
        setCustomizationData(configurationData.customizationData!);
        setIsCollectingBillingDetails(configurationData.collectBillingDetails);
        setIsTranslationAllowed(configurationData.isTranslationAllowed);
        initializeTranslationForWrapper(subscriptionId, configurationData.customizationData);
        setHasTrial(configurationData.hasTrial);

        if (options.withTimer) {
          options.isUpdatingPaymentMethod
            ? subscriptionClient
                .getSubscriptionPaymentUpdateData(token)
                .then((response) => {
                  setCustomRedirectUrl(response.data.failureRedirectUrl);
                  setTimeoutDate(new Date(Date.now() + response.data.timeoutValue));
                })
                .catch((ex) => goToSubscriptionErrorPage({ exception: ex }))
            : setTimeoutDate(new Date(Date.now() + configurationData.timeoutValue));
        }
        try {
          const { discount, productsSummary, correlationId, isFirstPaymentClosed, redirectUrl } = (
            await subscriptionClient.getStatus(subscriptionId)
          ).data;

          if (options.withRedirectOnFinishedSubscription && isFirstPaymentClosed) {
            goToUrl(redirectUrl);
            return;
          }

          if (productsSummary) {
            setPlanSummary({
              planInfo: {
                amountFormattedWithCurrency: productsSummary.amountFormattedWithCurrency,
                pricingIntervalCount: productsSummary.planPricingIntervalCount,
                trialIntervalCount: productsSummary.planTrialIntervalCount,
                description: productsSummary.planProductDescription,
                trialIntervalUnit: productsSummary.planTrialIntervalUnit,
                pricingIntervalUnit: productsSummary.planPricingIntervalUnit,
                orderId: correlationId,
              },
              isUpdatingPaymentMethod: options.isUpdatingPaymentMethod,
            });
          }

          if (discount?.discountDetails) {
            setDiscountInfo({
              name: discount.discountDetails.name!,
              discountAmountWithCurrency: discount.discountAmountFormattedWithCurrency!,
              discountedAmountFormattedWithCurrency:
                discount.discountedAmountFormattedWithCurrency!,
              durationInPeriods: discount.discountDetails.durationInPeriods,
            });
          }
        } catch (err) {
          goToSubscriptionErrorPage({ exception: err });
        } finally {
          setIsActive();
        }
      };

      const handleErrorPage = async () => {
        try {
          let customizationData: CustomizationData | undefined = undefined;
          if (subscriptionId) {
            const configurationData = (
              await subscriptionClient.getSubscriptionConfiguration(subscriptionId)
            ).data;
            customizationData = configurationData.customizationData;
          }
          initializeTranslationForWrapper(subscriptionId, customizationData);
        } catch (e) {
          logError(e);
        }
        setIsActive();
      };

      options.isErrorPage ? handleErrorPage() : loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionId, token]);

    useEffect(() => {
      const timeoutSubscription = () => {
        const delayTime = 1000;

        GoogleAnalyticsHelpers.UpdateDataLayer({
          category: 'Subscription',
          action: 'Timeout',
        });

        if (options.isUpdatingPaymentMethod) {
          customRedirectUrl && window.location.assign(customRedirectUrl);
          return;
        }

        const timeoutDelay = setTimeout(() => {
          subscriptionClient
            .timeoutSubscription(subscriptionId)
            .then((redirectUrl: AxiosResponse<string>) => {
              const url = redirectUrl.data;
              const call = `${url}`;
              window.location.assign(call);
            })
            .catch((ex) => goToSubscriptionErrorPage({ exception: ex }));

          clearTimeout(timeoutDelay);
        }, delayTime);
      };
      isTimeoutExpired && !isLoading && timeoutSubscription();
    }, [customRedirectUrl, isLoading, isTimeoutExpired, subscriptionId]);

    const linkCustomTexts: FooterLinkTexts = {
      accessibility: t('npp:general.footer.accessibility'),
      dataProtection: t('npp:general.footer.dataProtection'),
      privacyAndCookies: t('npp:general.footer.privacyAndCookies'),
      termsOfUse: t('npp:general.footer.termsOfUse'),
    };

    const timerOptions: TimerSectionProps | undefined =
      options.withTimer && timeoutDate
        ? {
            hidden: options.isTimerHidden,
            timeoutDate,
            onComplete: () => setIsTimeoutExpired(true),
          }
        : undefined;

    const showTrialAvailable =
      options.pageType !== PageType.Status &&
      !options.isUpdatingPaymentMethod &&
      planSummary &&
      hasTrial;

    const getPageTitle = () => {
      switch (options.pageType) {
        case PageType.UpdatePaymentMethod:
          return t('npp:stripe.subscription.titleUpdate');

        case PageType.Payment:
        default:
          return t('npp:subscription.title');
      }
    };

    if (!isActive) {
      return null;
    }

    return (
      <S.Root>
        <Header
          slogan={t('npp:general.checkout')}
          translations={getTranslationSettingsForHeader(
            subscriptionId,
            isTranslationAllowed,
            customizationData
          )}
          logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
        />
        <HeaderBar
          withBackButton={options.withBackButton}
          entity={{ type: 'Subscription', id: subscriptionId }}
          withConfirm={!options.isUpdatingPaymentMethod}
          customRedirectUrl={customRedirectUrl}
        />
        <CommonLayout
          key={i18next.language}
          alignCenter={options.alignCenter}
          topSection={
            <>
              {isErrorSummaryBoxVisible && <ErrorSummaryBox />}
              {showTrialAvailable && (
                <TrialInfo
                  unit={planSummary.planInfo.trialIntervalUnit}
                  count={planSummary.planInfo.trialIntervalCount}
                />
              )}
              {errorMessage && <ErrorMsg content={errorMessage} />}
              {options.withTitle && <PageTitle title={getPageTitle()} />}
            </>
          }
          mainSection={
            <>
              {options.withPaymentMethodChanger && activePaymentMethod && (
                <PaymentMethodChanger
                  entityId={subscriptionId}
                  clientSideData={{
                    chosenPaymentMethod: activePaymentMethod,
                    availablePaymentMethods: [activePaymentMethod],
                  }}
                  queryParams={queryParams}
                />
              )}
              <Component
                {...(props as T)}
                isUpdatingPaymentMethod={options.isUpdatingPaymentMethod}
                customRedirectUrl={customRedirectUrl}
                isCollectingBillingDetails={isCollectingBillingDetails}
                hasTrial={hasTrial}
                onErrorMessageChange={setErrorMessage}
              />
              <SafePaymentsBadge
                logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
              />
              {supportInfo && (
                <SupportSection
                  supportInfo={supportInfo.supportContactInformation}
                  correlationId={supportInfo.correlationId}
                />
              )}
            </>
          }
          sideSection={
            options.withSideSection ? (
              <CommonSubscriptionSideSection
                timer={timerOptions}
                planSummary={planSummary}
                discountInfo={discountInfo}
                isUpdatingPaymentMethod={options.isUpdatingPaymentMethod}
              />
            ) : null
          }
        />

        <main className="container-inset" id="main-section">
          {additionalSettings?.isSalesForceChatEnabled && (
            <EolChat
              eolChatDeploymentId={additionalSettings.salesForceChatDeploymentId!}
              eolChatOrganisationId={additionalSettings.salesForceChatOrganisationId!}
              eolApiEndpointId={additionalSettings.salesForceApiEndpointId!}
              eolChatUserId={additionalSettings.salesForceChatUserId!}
            />
          )}
        </main>

        <Footer
          linkUrls={{
            accessibility: customizationData.accessibilityUrl,
            dataProtection: customizationData.dataProtectionUrl,
            privacyAndCookies: customizationData.privacyAndCookiesUrl,
            termsOfUse: customizationData.termsOfUseUrl,
          }}
          copyrightOwner={customizationData.copyrightOwner}
          linkCustomTexts={linkCustomTexts}
        />
      </S.Root>
    );
  };
}
