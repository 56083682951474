export interface PaymentMethodLogoInfo {
  [key: string]: {
    img: React.ReactNode;
    isExternal: boolean;
  };
}

export const PAYMENT_METHOD_LOGO: PaymentMethodLogoInfo = {
  card: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/card_payment.svg" alt="card" />,
    isExternal: false,
  },
  banktransfer: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/bank_transfer.svg" alt="Bank Transfer" />,
    isExternal: false,
  },
  doku: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/doku.svg" alt="Doku" />,
    isExternal: true,
  },
  payu: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/payu.svg" alt="Payu" />,
    isExternal: true,
  },
  paypal: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/paypal.svg" alt="PayPal" />,
    isExternal: true,
  },
  seerbit: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/seerbit.svg" alt="Seerbit" />,
    isExternal: true,
  },
  onlinebankingindia: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/online_banking_india.svg" alt="Online Banking" />,
    isExternal: false,
  },
  walletsindia: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/mobile_payments.svg" alt="Wallets" />,
    isExternal: false,
  },
  paytm: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/paytm.svg" alt="PayTM" />,
    isExternal: true,
  },
  upi: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/upi.svg" alt="UPI" />,
    isExternal: true,
  },
  alipay: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/alipay.svg" alt="AliPay" />,
    isExternal: true,
  },
  wechatpay: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/wechatpay.svg" alt="Wechatpay" />,
    isExternal: true,
  },
  przelewy24: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/przelewy24.svg" alt="Przelewy24" />,
    isExternal: true,
  },
  klarna: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/klarna.svg" alt="Klarna" />,
    isExternal: true,
  },
  paydollar: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/paydollar.svg" alt="Paydollar" />,
    isExternal: true,
  },
  seerbitv2: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/seerbit.svg" alt="Seerbit" />,
    isExternal: true,
  },
  mobilemoney: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/mobile_payments.svg" alt="Mobile money" />,
    isExternal: false,
  },
  fpx: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/FPX.svg" alt="FPX" />,
    isExternal: true,
  },
  grabpay: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/grabpay.svg" alt="Grabpay" />,
    isExternal: true,
  },
  esewa: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/esewa.svg" alt="eSewa" />,
    isExternal: true,
  },
  bancontact: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/bancontact.svg" alt="Bancontact" />,
    isExternal: true,
  },
  blik: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/blik.svg" alt="Blik" />,
    isExternal: true,
  },
  multibanco: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/multibanco.svg" alt="Multibanco" />,
    isExternal: true,
  },
  mobilebanking: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/mobile_banking.svg" alt="Mobilebanking" />,
    isExternal: false,
  },
  netbanking: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/net_banking.svg" alt="Netbanking" />,
    isExternal: false,
  },
  atmcard: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/card_payment.svg" alt="Card" />,
    isExternal: false,
  },
  internationalcard: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/international_card.svg" alt="Card" />,
    isExternal: false,
  },
  qrcode: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/qr_code.svg" alt="QR" />,
    isExternal: false,
  },
  vietqr: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/vietQR.svg" alt="Alepay Viet QR" />,
    isExternal: true,
  },
  ideal: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/ideal.svg" alt="Ideal" />,
    isExternal: true,
  },
  upistripe: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/upi.svg" alt="UPI" />,
    isExternal: true,
  },
  paybybank: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/pay_by_bank.svg" alt="Pay By Bank" />,
    isExternal: false,
  },
  cybersource: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/cybersource.svg" alt="Cybersource" />,
    isExternal: true,
  },
  pix: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/pix.svg" alt="pix" />,
    isExternal: true,
  },
  promptpay: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/promptpay.svg" alt="Promptpay" />,
    isExternal: true,
  },
  fiuubanktransfers: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/fiuu_bank_transfer.svg" alt="Online Bank Transfer" />,
    isExternal: false,
  },
  syncbanktransfer: {
    img: <img src="https://bccdn.britishcouncil.org/dev/online-payment-icons/v2/sync_bank_transfer.svg" alt="Online Bank Transfer" />,
    isExternal: false,
  }
};

export const getPaymentMethodLogoOrDefault = (method: string): PaymentMethodLogoInfo[string] => {
  const normalizedMethod = method.toLowerCase();
  return PAYMENT_METHOD_LOGO[normalizedMethod] || PAYMENT_METHOD_LOGO.card;
}
