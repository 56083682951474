import styled from '@emotion/styled';

export const Root = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: -15px;

  ${({ theme }) => theme.mediaQueries.md} {
    flex-direction: row;
  }
`;

export const SafePaymentsBox = styled.div`
  width: 320px;
  height: 48px;
  background: linear-gradient(to right, #23085a, #009d8c);
  border-radius: 75px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 5px;
  padding: 10px 20px;

  img {
    max-width: 50%;
    max-height: 30px;
  }
`;
