/* eslint-disable complexity */
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  AdditionalSettings,
  CustomizationData,
  SupportContactInformationRecord,
  instalmentPlanClient,
} from '../../../../client/base';
import { useBoolean } from '@britishcouncil/react-common';
import { checkIfImageOrDefault, goToUrl, INSTALMENT_PLAN_IDENTIFIER_NAME } from '../../../../utils';
import { configureTrackingProps, EntityType, logError } from '../../../../utils/logger-utils';
import { startAppInsightTracking } from '../../applicatonInsights';
import { setAxiosHeaders } from '../../../../utils/auditInterceptorsAxiosConfig';
import { HeaderBar, SafePaymentsBadge, TimerSectionProps } from '../../../../shared';
import { EolChat } from '../../EolChat';
import { GoogleAnalyticsHelpers } from '../../../../googleAnalytics/GoogleAnalyticsHelpers';
import { useLoadingContext } from '../../../../App';
import { AxiosResponse } from 'axios';
import { Footer, FooterLinkTexts, Header } from '@britishcouncil/react-extro-ui';
import i18next, {
  getTranslationSettingsForHeader,
  initializeTranslationForWrapper,
} from '../../../../utils/i18n';
import * as S from './instalmentPlanWrappers.styles';
import CommonLayout from '../../../../shared/layout/commonLayout/CommonLayout';
import {
  AvailablePaymentMethod,
  ErrorMsg,
  InstalmentsSummaryProps,
  PageTitle,
  SupportSection,
} from '../../..';
import PaymentMethodChanger from '../../../../shared/paymentMethodChanger/PaymentMethodChanger';
import CommonInstalmentPlanSideSection from '../../../../shared/layout/sideSection/instalmentPlan/CommonInstalmentPlanSideSection';
import { PageType } from '../types';
import { FiCreditCard } from 'react-icons/fi';
import { useNavigateToErrorPage, useQueryParams } from '../../../../hooks';
import { useErrorSummaryContext } from '@contexts';
import ErrorSummaryBox from '../../../../shared/errorSummary/ErrorSummaryBox';
import { InstalmentPlanErrorPath } from '../../../../routes/PathConsts';

interface InstalmentPlanHeaderAndFooterOptions {
  withTimer?: boolean;
  customRedirectUrl?: string;
  withBackButton?: boolean;
  isErrorPage?: boolean;
  isTimerHidden?: boolean;
  withRedirectOnFinishedInstalmentPlan?: boolean;
  isUpdatingPaymentMethod?: boolean;
  withSideSection?: boolean;
  withPaymentMethodChanger?: boolean;
  withTitle?: boolean;
  pageType?: PageType;
  alignCenter?: boolean;
}

export function instalmentPlanWithHeaderAndFooter<T>(
  Component: React.ComponentType<T>,
  options: InstalmentPlanHeaderAndFooterOptions = {
    withBackButton: false,
    isErrorPage: true,
    withTimer: false,
    isTimerHidden: false,
    withRedirectOnFinishedInstalmentPlan: false,
    isUpdatingPaymentMethod: false,
    withSideSection: false,
    withPaymentMethodChanger: false,
    withTitle: false,
    pageType: PageType.Payment,
    alignCenter: false,
  }
) {
  // eslint-disable-next-line complexity
  return (
    props: Omit<
      T,
      | 'isCollectingBillingDetails'
      | 'customRedirectUrl'
      | 'isUpdatingPaymentMethod'
      | 'onErrorMessageChange'
    >
  ) => {
    const { isLoading } = useLoadingContext();
    const { isErrorSummaryBoxVisible } = useErrorSummaryContext();
    const [customizationData, setCustomizationData] = useState<CustomizationData>({});
    const [isTranslationAllowed, setIsTranslationAllowed] = useBoolean();
    const [additionalSettings, setAdditionalSettings] = useState<AdditionalSettings>();
    const [timeoutDate, setTimeoutDate] = useState<Date>();
    const [isActive, setIsActive] = useBoolean(false);
    const [isTimeoutExpired, setIsTimeoutExpired] = useState<boolean>(false);
    const [isCollectingBillingDetails, setIsCollectingBillingDetails] = useBoolean();
    const [customRedirectUrl, setCustomRedirectUrl] = useState<string>();
    const [activePaymentMethod, setActivePaymentMethod] = useState<AvailablePaymentMethod>();
    const [errorMessage, setErrorMessage] = useState<string>();
    const [instalmentsSummary, setInstalmentsSummary] = useState<
      InstalmentsSummaryProps | undefined
    >(undefined);
    const [supportInfo, setSupportInfo] = useState<SupportContactInformationRecord>();

    const queryParams = useQueryParams();
    const token = queryParams.getParam('token');
    const instalmentPlanId = queryParams.getInstalmentPlanId();
    const { goToInstalmentPlanErrorPage } = useNavigateToErrorPage();

    const { t } = useTranslation(['npp']);

    useEffect(() => {
      if (instalmentPlanId)
        instalmentPlanClient.getSupportContactInformation(instalmentPlanId).then((result) => {
          if (result.data) setSupportInfo(result.data);
        });
    }, [instalmentPlanId]);

    useEffect(() => {
      const loadData = async () => {
        if (!instalmentPlanId) {
          goToUrl(InstalmentPlanErrorPath);
        }

        localStorage.setItem(INSTALMENT_PLAN_IDENTIFIER_NAME, instalmentPlanId);
        try {
          const configurationData = (await instalmentPlanClient.getConfiguration(instalmentPlanId))
            .data;

          configureTrackingProps(
            configurationData.instalmentPlanId,
            EntityType.instalmentPlan,
            configurationData.correlationSessionId!
          );

          startAppInsightTracking();

          if (configurationData.additionalSettings) {
            setAdditionalSettings(configurationData.additionalSettings);
          }

          setActivePaymentMethod({
            name: configurationData.providerPaymentMethodGroupCode,
            shortCode: configurationData.providerPaymentMethodGroupCode,
            logo: {
              img: <FiCreditCard size={33} />,
              isExternal: false,
            },
            methodGroupId: 0,
          });

          setAxiosHeaders(configurationData.correlationSessionId!);
          setCustomizationData(configurationData.customizationData!);
          setIsCollectingBillingDetails(configurationData.collectBillingDetails);
          setIsTranslationAllowed(configurationData.isTranslationAllowed);
          initializeTranslationForWrapper(instalmentPlanId, configurationData.customizationData);

          if (options.withTimer) {
            if (options.isUpdatingPaymentMethod) {
              try {
                const response =
                  await instalmentPlanClient.getInstalmentPlanPaymentUpdateData(token);
                setCustomRedirectUrl(response.data.failureRedirectUrl);
                setTimeoutDate(new Date(Date.now() + response.data.timeoutValue));
              } catch (ex) {
                goToInstalmentPlanErrorPage({ exception: ex });
              }
            } else {
              setTimeoutDate(new Date(Date.now() + configurationData.timeoutValue));
            }
          }
        } catch (err) {
          goToInstalmentPlanErrorPage({ exception: err });
        }

        try {
          const {
            sumAmountFormattedWithCurrency,
            lineItems,
            instalments,
            correlationId,
            isFirstPaymentClosed,
            statusUrl,
          } = (await instalmentPlanClient.getStatus(instalmentPlanId)).data;

          if (options.withRedirectOnFinishedInstalmentPlan && isFirstPaymentClosed) {
            goToUrl(statusUrl);
            return;
          }

          setInstalmentsSummary({
            instalmentsInfo: {
              totalAmount: sumAmountFormattedWithCurrency,
              lineItems: lineItems,
              instalments: instalments,
              orderId: correlationId,
            },
            isUpdatingPaymentMethod: options.isUpdatingPaymentMethod,
          });
        } catch (err) {
          goToInstalmentPlanErrorPage({ exception: err });
        } finally {
          setIsActive();
        }
      };

      const handleErrorPage = async () => {
        try {
          let customizationData: CustomizationData | undefined = undefined;
          if (instalmentPlanId) {
            const configurationData = (
              await instalmentPlanClient.getConfiguration(instalmentPlanId)
            ).data;
            customizationData = configurationData.customizationData;
          }
          initializeTranslationForWrapper(instalmentPlanId, customizationData);
        } catch (e) {
          logError(e);
        }
        setIsActive();
      };

      options.isErrorPage ? handleErrorPage() : loadData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [instalmentPlanId]);

    useEffect(() => {
      const timeoutInstalmentPlan = () => {
        const delayTime = 1000;

        GoogleAnalyticsHelpers.UpdateDataLayer({
          category: 'InstalmentPlan',
          action: 'Timeout',
        });

        if (options.isUpdatingPaymentMethod) {
          customRedirectUrl && window.location.assign(customRedirectUrl);
          return;
        }

        const timeoutDelay = setTimeout(() => {
          instalmentPlanClient
            .timeout(instalmentPlanId)
            .then((redirectUrl: AxiosResponse<string>) => {
              const url = redirectUrl.data;
              const call = `${url}`;
              window.location.assign(call);
            })
            .catch((ex) => goToInstalmentPlanErrorPage({ exception: ex }));

          clearTimeout(timeoutDelay);
        }, delayTime);
      };
      isTimeoutExpired && !isLoading && timeoutInstalmentPlan();
    }, [customRedirectUrl, isLoading, isTimeoutExpired, instalmentPlanId]);

    const linkCustomTexts: FooterLinkTexts = {
      accessibility: t('npp:general.footer.accessibility'),
      dataProtection: t('npp:general.footer.dataProtection'),
      privacyAndCookies: t('npp:general.footer.privacyAndCookies'),
      termsOfUse: t('npp:general.footer.termsOfUse'),
    };

    const getPageTitle = () => {
      switch (options.pageType) {
        case PageType.UpdatePaymentMethod:
          return t('npp:stripe.instalmentPlan.updatePaymentMethod');

        case PageType.Status:
          return t('npp:instalmentPlanStatusPage.title');

        case PageType.Payment:
        default:
          return t('npp:stripe.instalmentPlan.makeFirstPaymentToStart');
      }
    };

    if (!isActive) {
      return null;
    }

    const timerOptions: TimerSectionProps | undefined =
      options.withTimer && timeoutDate
        ? {
            hidden: options.isTimerHidden,
            timeoutDate,
            onComplete: () => setIsTimeoutExpired(true),
          }
        : undefined;

    return (
      <S.Root>
        <Header
          slogan={t('npp:general.checkout')}
          translations={getTranslationSettingsForHeader(
            instalmentPlanId,
            isTranslationAllowed,
            customizationData
          )}
          logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
        />
        <HeaderBar
          withBackButton={options.withBackButton}
          entity={{ type: 'InstalmentPlan', id: instalmentPlanId }}
          withConfirm={!options.isUpdatingPaymentMethod}
          customRedirectUrl={customRedirectUrl}
        />
        <CommonLayout
          key={i18next.language}
          alignCenter={options.alignCenter}
          topSection={
            <>
              {isErrorSummaryBoxVisible && <ErrorSummaryBox />}
              {errorMessage && <ErrorMsg content={errorMessage} />}
              {options.withTitle && <PageTitle title={getPageTitle()} />}
            </>
          }
          mainSection={
            <>
              {options.withPaymentMethodChanger && activePaymentMethod && (
                <PaymentMethodChanger
                  entityId={instalmentPlanId}
                  clientSideData={{
                    chosenPaymentMethod: activePaymentMethod,
                    availablePaymentMethods: [activePaymentMethod],
                  }}
                  queryParams={queryParams}
                />
              )}
              <Component
                {...(props as T)}
                isCollectingBillingDetails={isCollectingBillingDetails}
                onErrorMessageChange={setErrorMessage}
              />
              <SafePaymentsBadge
                logoUrl={checkIfImageOrDefault(customizationData.paymentPageLogoImageUrl)}
              />
              {supportInfo && (
                <SupportSection
                  supportInfo={supportInfo.supportContactInformation}
                  correlationId={supportInfo.correlationId}
                />
              )}
            </>
          }
          sideSection={
            options.withSideSection ? (
              <CommonInstalmentPlanSideSection
                timer={timerOptions}
                instalmentsSummary={instalmentsSummary}
                isUpdatingPaymentMethod={options.isUpdatingPaymentMethod}
              />
            ) : null
          }
        />

        <main className="container-inset" id="main-section">
          {additionalSettings?.isSalesForceChatEnabled && (
            <EolChat
              eolChatDeploymentId={additionalSettings.salesForceChatDeploymentId!}
              eolChatOrganisationId={additionalSettings.salesForceChatOrganisationId!}
              eolApiEndpointId={additionalSettings.salesForceApiEndpointId!}
              eolChatUserId={additionalSettings.salesForceChatUserId!}
            />
          )}
        </main>

        <Footer
          linkUrls={{
            accessibility: customizationData.accessibilityUrl,
            dataProtection: customizationData.dataProtectionUrl,
            privacyAndCookies: customizationData.privacyAndCookiesUrl,
            termsOfUse: customizationData.termsOfUseUrl,
          }}
          copyrightOwner={customizationData.copyrightOwner}
          linkCustomTexts={linkCustomTexts}
        />
      </S.Root>
    );
  };
}
