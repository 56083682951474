import { RefObject } from 'react';
import axios from 'axios';
import { payServiceClient } from '../client/base';
import { logError, logEvent } from './logger-utils';
import {
  INSTALMENT_PLAN_IDENTIFIER_NAME,
  PAYMENT_IDENTIFIER_NAME,
  SUBSCRIPTION_IDENTIFIER_NAME,
} from './identifiers';
import { v4, validate as uuidValidate } from 'uuid';
import {
  GenericErrorPath,
  InstalmentPlanErrorPath,
  PaymentErrorPagePath,
  SubscriptionErrorPagePath,
} from '../routes/PathConsts';

export type FormRefs = { [string: string]: React.RefObject<HTMLDivElement> };

/**
 * Extracts query parameter from the URL
 * @param paramName
 */
export const getQueryParam = (paramName: string): string => {
  const params = decodeURLParams(window.location.search);
  return params[paramName.toLowerCase()];
};

export const decodeURLParams = (search: string): any => {
  const hashes = search.slice(search.indexOf('?') + 1).split('&');
  return hashes.reduce((params, hash) => {
    const split = hash.indexOf('=');

    if (split < 0) {
      return Object.assign(params, {
        [hash]: null,
      });
    }

    const key = hash.slice(0, split).replace('?', '').toLowerCase();
    const val = hash.slice(split + 1);

    return { ...params, [key]: decodeURIComponent(val) };
  }, {});
};

/**
 * Redirects to a different page / URL
 * @param url URL to redirect to
 * @param noBack When true back button will not be able to navigate back to the prev page
 */
export const goToUrl = (url: string, noBack = true) => {
  if (noBack) {
    // Why use replace? https://stackoverflow.com/a/42469170/864968
    window.location.replace(url);
  } else {
    window.location.href = url;
  }
};

export const goToStatusPage = () => {
  const paymentId = getEntityIdFromQuery(PAYMENT_IDENTIFIER_NAME);
  goToUrl(window.location.origin + '/payment/status?paymentId=' + paymentId);
};

export const goToErrorPage = (exception?: any) => {
  if (exception) {
    logError(exception);
  }

  logEvent({
    message: 'Redirect to generic error page',
    level: 'warning',
    extra: { fromUrl: window.location.href },
  });

  let paymentId = getQueryParam(PAYMENT_IDENTIFIER_NAME);
  if (paymentId) return goToUrl(`${PaymentErrorPagePath}?${PAYMENT_IDENTIFIER_NAME}=${paymentId}`);

  paymentId = getQueryParam(PAYMENT_IDENTIFIER_NAME.toLocaleLowerCase());
  if (paymentId) return goToUrl(`${PaymentErrorPagePath}?${PAYMENT_IDENTIFIER_NAME}=${paymentId}`);

  let subscriptionId = getQueryParam(SUBSCRIPTION_IDENTIFIER_NAME);
  if (subscriptionId)
    return goToUrl(
      `${SubscriptionErrorPagePath}?${SUBSCRIPTION_IDENTIFIER_NAME}=${subscriptionId}`
    );

  subscriptionId = getQueryParam(SUBSCRIPTION_IDENTIFIER_NAME.toLocaleLowerCase());
  if (subscriptionId)
    return goToUrl(
      `${SubscriptionErrorPagePath}?${SUBSCRIPTION_IDENTIFIER_NAME}=${subscriptionId}`
    );

  let instalmentPlanId = getQueryParam(INSTALMENT_PLAN_IDENTIFIER_NAME);
  if (instalmentPlanId)
    return goToUrl(
      `${InstalmentPlanErrorPath}?${INSTALMENT_PLAN_IDENTIFIER_NAME}=${instalmentPlanId}`
    );

  instalmentPlanId = getQueryParam(INSTALMENT_PLAN_IDENTIFIER_NAME.toLocaleLowerCase());
  if (instalmentPlanId)
    return goToUrl(
      `${InstalmentPlanErrorPath}?${INSTALMENT_PLAN_IDENTIFIER_NAME}=${instalmentPlanId}`
    );

  goToUrl(`${window.location.origin}${GenericErrorPath}`);
};

export const goToExpiredPage = () => {
  const paymentId = getEntityIdFromQuery(PAYMENT_IDENTIFIER_NAME);

  logEvent({
    message: 'Redirect to expired page',
    level: 'warning',
    extra: { paymentId: paymentId, fromUrl: window.location.href },
  });

  goToUrl(window.location.origin + '/expired?paymentId=' + paymentId);
};

export const goToFinalErrorPage = (exception?: any) => {
  goToErrorPage(exception);
};

export const convertToBoolean = (input: string | boolean): boolean | undefined => {
  if (typeof input === 'boolean') return input;

  return !input ? undefined : input.toLowerCase() === 'true';
};

/**
 * Scroll browser window to referenced element position
 * @param ref
 */
export const scrollToRef = (ref: RefObject<any>) => {
  ref?.current?.scrollIntoView({ behavior: 'smooth' });
};

export const randomUuid = () => v4();

export function hasFlag<T extends number>(flagSet: T, flag: T): boolean {
  // eslint-disable-next-line no-bitwise
  return (flagSet & flag) === flag;
}

export const removeInitialLoader = () => {
  const previevLoader = document.getElementById('preview');
  const root = document.getElementById('root');
  if (previevLoader && root) {
    previevLoader.remove();
    root.classList.remove('hidden-root');
  }
};

export const scrollToFirstErrorField = (
  formFields: Record<string, any>,
  errors: {
    [field: string]:
      | {
          messageKey: string;
          args?: { [field: string]: string };
        }
      | undefined;
  },
  fieldRefs: FormRefs
) => {
  for (const formField of Object.values(formFields)) {
    if (errors[formField]) {
      scrollToRef(fieldRefs[formField]);
      break;
    }
  }
};

const getEntityIdFromQuery = (entityName: string) => {
  const entityId = getQueryParam(entityName);

  if (!entityId || entityId === 'undefined') {
    logEvent({
      message: `${entityName} is empty`,
      level: 'error',
      extra: { fromUrl: window.location.href },
    });
  }

  return entityId;
};

let ip: string;

export const getPublicIp = async (): Promise<string> => {
  if (ip) {
    return ip;
  }

  ip = (await payServiceClient.getUserInfo()).data;
  if (ip) {
    return ip;
  }

  ip = (await getIpFromIpify()).data;

  return ip;
};

const getIpFromIpify = () =>
  axios.request<string>({
    url: 'https://api64.ipify.org',
    method: 'GET',
  });

export const nameof = <T>(name: Extract<keyof T, string>): string => name;
export const formFieldId = <T>(name: Extract<keyof T, string>): string => `#${name}`;

export const checkIfImageOrDefault = (url?: string): string =>
  url && url.length > 0 && url.match(/\.(jpeg|jpg|gif|png|svg|webp)$/) != null
    ? url
    : 'https://bccdn.azureedge.net/dev/bc-logo-white.svg';

type QueryParamsDictionary = {
  [key: string]: string;
};

export class QueryParams {
  private queryParams: QueryParamsDictionary;

  constructor(urlSearchParams: URLSearchParams) {
    this.queryParams = Array.from(urlSearchParams.entries()).reduce(
      (obj: QueryParamsDictionary, [key, value]) => {
        obj[key.toLowerCase()] = value;
        return obj;
      },
      {}
    );
  }

  markUrlWithCspUpdate(): void {
    this.queryParams['secured'] = new Date().getTime().toString();
  }

  removeCspUpdateMark(): void {
    delete this.queryParams['secured'];
  }

  isMarkedAsCspUpdate(): boolean {
    return this.getParam('secured') != undefined;
  }

  getPaymentId(): string {
    const paymentId = this.getParam(PAYMENT_IDENTIFIER_NAME);

    if (!uuidValidate(paymentId)) {
      logEvent({
        message: 'PaymentId is invalid',
        level: 'error',
        extra: { fromUrl: window.location.href, paymentId },
      });

      return '';
    }

    return paymentId;
  }

  getSubscriptionId(): string {
    const subscriptionId = this.getParam(SUBSCRIPTION_IDENTIFIER_NAME);

    if (!uuidValidate(subscriptionId)) {
      logEvent({
        message: 'SubscriptionId is invalid',
        level: 'error',
        extra: { fromUrl: window.location.href, subscriptionId },
      });

      return '';
    }
    return subscriptionId;
  }

  getInstalmentPlanId(): string {
    const instalmentPlanId = this.getParam(INSTALMENT_PLAN_IDENTIFIER_NAME);

    if (!uuidValidate(instalmentPlanId)) {
      logEvent({
        message: 'InstalmentPlanId is invalid',
        level: 'error',
        extra: { fromUrl: window.location.href, instalmentPlanId },
      });

      return '';
    }

    return instalmentPlanId;
  }

  getParam(key: string): string {
    return this.queryParams[key.toLowerCase()];
  }

  getAll(): QueryParamsDictionary {
    return this.queryParams;
  }

  toString(): string {
    return `${Object.entries(this.queryParams)
      .map(([key, value]) => key + '=' + value)
      .join('&')}`;
  }
}
