import * as S from './SafePaymentsBadge.styles';

interface Props {
  logoUrl: string;
}

export const SafePaymentsBadge = ({ logoUrl }: Props) => (
  <S.Root>
    <S.SafePaymentsBox>
      <img src={logoUrl} alt="Company logo" />
      <img
        src="https://bccdn.britishcouncil.org/dev/npp/safe_payments_badge.png"
        alt="British Council safe payments"
      />
    </S.SafePaymentsBox>
  </S.Root>
);
